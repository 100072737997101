import dynamic from 'next/dynamic';
import composeServerSideProps from '../utils/compose-ssp';
import LinkMapping from '../utils/links';
import headers from '../utils/headers';

const StartPage = dynamic(() => import('../containers/StartPage'));
const StartPageUser = dynamic(() => import('../containers/StartPageUser'));
const CreateAccountPage = dynamic(() =>
  import('../containers/CreateAccountPage')
);
const NonStockholmUserPage = dynamic(() =>
  import('../containers/NonStockholmUserPage')
);
const StartPageAdvisor = dynamic(() =>
  import('../containers/StartPageAdvisor')
);

export async function getServerSideProps(ctx) {
  const props = await composeServerSideProps([], ctx);

  let response = await fetch(LinkMapping('USER_SETTINGS'), {
    headers: headers(ctx.req.headers),
  });
  const startPage = await response.json();
  props.props = { ...props.props, ...startPage };

  if (props.props?.user?.role === 'customer') {
    response = await fetch(LinkMapping('BOOKING'), {
      headers: headers(ctx.req.headers),
    });
    const bookings = await response.json();
    props.props.bookings = bookings;
  }

  if (
    props.props?.isAuthenticated &&
    props.props?.user?.livesInStockholm &&
    !props.props?.user?.acceptedTos
  ) {
    response = await fetch(LinkMapping('PAGE_CREATE_ACCOUNT'), {
      headers: headers(ctx.req.headers),
    });
    const createAccountPageData = await response.json();
    props.props = { ...props.props, ...createAccountPageData };
  }

  return props;
}

export default function StartPage_(props) {
  let components = {
    default: StartPageUser,
    advisor: StartPageUser,
    user: StartPageUser,
    // default: StartPage,
    // advisor: StartPageAdvisor,
    acceptTosPage: CreateAccountPage,
    nonStockholmUserPage: NonStockholmUserPage,
  };
  let Component = components.default;

  if (
    props.isAuthenticated &&
    ['supplier', 'advisor', 'supplier-admin', 'superuser'].includes(
      props.user.role
    )
  ) {
    Component = components.advisor;
  } else if (
    props.isAuthenticated &&
    props.user.livesInStockholm &&
    !props.user.acceptedTos
  ) {
    Component = components.acceptTosPage;
  } else if (props.isAuthenticated && !props.user.livesInStockholm) {
    Component = components.nonStockholmUserPage;
  } else if (props.isAuthenticated && props.user.noPersonpostFromEps) {
    Component = components.nonStockholmUserPage;
  } else if (props.isAuthenticated) {
    Component = components.user;
  }

  return <Component {...props} />;
}
